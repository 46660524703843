<template>
  <div
    ref="signinBtn"
    class="google-btn"
  >
    <div class="google-icon-wrapper">
      <img
        class="google-icon"
        :src="require('@/assets/google_sso_logo.svg')"
      >
    </div>
    <p class="btn-text">
      <b>Sign in with google</b>
    </p>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    params: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  setup() {

  },
  data() {
    return {
      googleLogo: require('@/assets/google_sso_logo.svg'),
    }
  },
  mounted() {
    const { gapi } = window

    const authPatams = this.params

    const handleSignIn = (authInstance, isSignedIn, autoLogin) => {
      if (isSignedIn) {
        const googleUser = authInstance.currentUser.get()
        this.$emit('success', { googleUser, authContext: authInstance, auto: autoLogin })
      } else {
        this.$emit('login')
      }
    }

    gapi.load('client:auth2', () => {
      gapi.client.init(authPatams).then(() => {
        const authInstance = gapi.auth2.getAuthInstance()
        authInstance.isSignedIn.listen(isSignedIn => { handleSignIn(authInstance, isSignedIn) })
        handleSignIn(authInstance, authInstance.isSignedIn.get(), true)

        authInstance.attachClickHandler(this.$refs.signinBtn, {}, () => {
          handleSignIn(authInstance, authInstance.isSignedIn.get(), false)
        }, error => {
          this.$emit('error', error)
          this.$emit('failure', error) // an alias
        })
      })
    })
  },
})
</script>

<style lang="scss">

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 184px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  box-sizing: content-box;

  .google-icon-wrapper {
  box-sizing: content-box;
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);
</style>

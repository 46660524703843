<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">
            JOURNEYZ
          </h2>
        </b-link>

        <div
          style="display: flex; justify-content: center"
        >
          <b-spinner
            v-show="isLoading"
            variant="primary"
            label="Loading..."
          />

          <GoogleSignin
            v-show="!isLoading"
            :params="googleSignInParams"
            @success="onSignInSuccess"
            @error="onSignInError"
            @login="onLoginRequired"
          />
        </div>

      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import {
  BCard, BLink, BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { createNamespacedHelpers } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import GoogleSignin from '@/modules/core/components/GoogleSignin.vue'
import * as ActionTypes from '@/store/global-action-types'

const { mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    // BSV
    BCard,
    BLink,
    VuexyLogo,
    GoogleSignin,
    BSpinner,
  },
  directives: { Ripple },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loginToken: '', // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjA3MDMzZTRmMTE4MGMzZmYxZDliYTNlY2NlYTdhZjgzMTk0MDI1YmQifQ.eyJleHAiOjE2MjY3NDMyMDR9.EsY63iHRCosBDsCjfsKk4FEFS81kA7NEFnIUthlSFVE',
      isLoading: true,
      required,
      googleSignInParams: {
        clientId: '472268685167-kach22kljfh1v3l2ee9733a0fqe5qhlm.apps.googleusercontent.com',
        scope: 'profile email openid',
      },
    }
  },
  computed: {
  },
  beforeCreate() {

  },
  methods: {
    doLogin() {
      this.tokenLogin(this.loginToken).then(() => this.$router.push('/'))
    },
    onSignInSuccess({ authContext, auto }) {
      this.isLoading = true
      this.validateUser({ authContext, showError: !auto }).then(() => {
        this.$router.push('/')
      }, () => {
        this.isLoading = false
      })

      // this.validateUser(authContext).then(result => {
      //   console.log('validate done', result)
      // }, error => {
      //   console.log('validate error', error)
      // })
    },
    onSignInError() {
      this.isLoading = false
    },
    onLoginRequired() {
      this.isLoading = false
    },
    ...mapActions({ validateUser: ActionTypes.VALIDATE_USER_LOGIN }),
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
